<template>
  <div class="full-page">
    <div class="main">
      <img v-if="payStatus" src="@/assets/success.png">
       <img v-else src="@/assets/failed.png">
      <div class="result-column">
        <div class="result-text"><span>{{payStatus? '预约成功' : '预约失败'}}</span></div>
        <template v-if="payStatus">
          <div class="result-tips"><span>恭喜你预约成功，您的预约编号为：</span></div>
          <div class="appointment-date">
            <div class="top">
              <span>{{appointmentDetail.reservationTime + " "}}</span>
              <span>{{appointmentDetail.reservationTime | filterDate}}</span>
              <span>{{periodStatus[appointmentDetail.period]}}</span>
            </div>
            <div class="bottom">
              <span>第</span>
              <span class="number">{{appointmentDetail.numbering}}</span>
              <span>号</span>
            </div>
          </div>
        </template>
        <template v-else>
            <div class="result-tips">
              <div><span>您未支付成功，请尽快支付，</span></div>
              <div><span class="time-limit">15分钟内</span><span>未支付则自动取消预约记录</span></div>
            </div>
        </template>
      </div>
    </div>

    <div class="check-record">
      <van-button v-if="payStatus" block color="#00B6A4" @click="$router.push('/appointment-record')">查看预约记录</van-button>
      <van-button v-else block color="#00B6A4" @click="toPay">去支付</van-button>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        appointmentDetail: {},
        payStatus: '',
        periodStatus: {
          MORNING: '上午',
          AFTERNOON: '下午',
          NIGHT: '晚上',
        }
      }
    },

    computed: {
      ...mapState({
        success: (state) => state.user.payResult,
        payInfo: (state) => state.user.payInfo,
        orderIdOrSN: (state) => state.user.orderIdOrSN
      }),
    },

    created() {
      this.getAppointmentDetail()
    },

    methods: {
      toPay () {
        this.$router.push({path: '/pay-page', name: 'pay-page', params: {orderIdOrSN: this.orderIdOrSN}})
      },

      getAppointmentDetail () {
        this.$request ({
          url: '/reservation/detail/' + this.orderIdOrSN,
          data: {}
        }).then( res => {
          this.appointmentDetail = res.result
          this.payStatus = this.appointmentDetail.status === 'PAY'
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .full-page {
    padding: .24rem;
    .main {
      padding: .6rem .24rem;
      background: #fff;
      border-radius: .16rem;
      text-align: center;
      img {
        width: 1.28rem;
        display: block;
        margin: 0 auto;
      }
      .result-column {
        .result-text {
          font-size: .36rem;
          margin: .4rem 0 .2rem 0;
        }
        .result-tips {
          font-size: .26rem;
          color: #999999;
          margin-bottom: .4rem;
          line-height: .4rem;
          .time-limit {
            color: #00B6A4;
          }
        }
        .appointment-date {
          background: #E6FFFC;
          font-weight: bold;
          padding: .5rem;
          border-radius: .08rem;
          .top {
            font-size: .36rem;
            margin-bottom: .2rem;
          }
          .bottom {
            color: #00B6A4;
            font-size: .3rem;
            .number {
              font-size: .6rem;
            }
          }
        }
      }
    }

    .check-record {
      margin-top: 1rem;
      border-radius: .08rem;
      overflow: hidden;
    }
  }
</style>